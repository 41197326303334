<div fxLayout="column" fxFlexFill>
  <header class="content-padding">
    <h1>Hollywood Timeline</h1>
  </header>

  <main fxFlex class="content-padding">
    <router-outlet></router-outlet>
  </main>

  <footer
    class="content-padding"
    fxLayout
    fxLayout.xs="column"
    fxLayoutAlign="space-between"
    fxLayoutAlign.xs="center center"
  >
    <div>A <a href="https://www.linkedin.com/in/joshglazer/">Josh Glazer</a> project</div>
    <div><a href="https://github.com/joshglazer/hollywood-timeline">Source code on GitHub</a></div>
  </footer>
</div>
